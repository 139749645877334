'use client'

import ClientForm from './ClientForm'
import IFrameForm from './IFrameForm'
import PlatformForm from './PlatformForm'
import useDonationForm from './useDonationForm'
import { ActionFormProvider } from '@/form/ActionForm'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { type Stripe, loadStripe } from '@stripe/stripe-js'

import useLocale from '@/i18n/useLocale'
import useUpdateCodonationOnDonationChange from '@/donationForm/_dependencies/components/DonationForm/useUpdateCodonationOnDonationChange'
import { StripeContextProvider } from '@/donationForm/_dependencies/StripeContext'
import { useEffect, useState } from 'react'
import type { DonationFormProps, DonationFormValues } from '@/donationForm/types'

function FormWithStripeContext(props: DonationFormProps & { initialValues: DonationFormValues }) {
  const stripe = useStripe()
  const elements = useElements()
  // const value = useMemo(() => ({ stripe, elements }), [stripe, elements])
  return (
    <StripeContextProvider value={{ stripe, elements }}>
      <FormSetup {...props} />
    </StripeContextProvider>
  )
}

function useStripePromise(stripeKey: string) {
  const [promise, setPromise] = useState<Promise<Stripe | null> | null>(null)
  useEffect(() => {
    if (typeof window === 'undefined' || !stripeKey) return
    setPromise(loadStripe(stripeKey))
  }, [stripeKey])
  return promise
}

function Form(props: DonationFormProps) {
  useUpdateCodonationOnDonationChange()
  switch (props.config.layout) {
    case 'donate/platform':
      return <PlatformForm {...props} />
    case 'donate/iframe':
      return <IFrameForm {...props} />
    default:
      return <ClientForm {...props} />
  }
}

// eslint-disable-next-line import/exports-last
export function FormSetup({ config, initialValues }: DonationFormProps & { initialValues: DonationFormValues }) {
  const { action, onSubmit, id, ...providerProps } = useDonationForm(initialValues)

  return (
    <ActionFormProvider {...providerProps}>
      <Form config={config} action={action} onSubmit={onSubmit} id={id} />
    </ActionFormProvider>
  )
}

function DonationForm(props: DonationFormProps & { initialValues: DonationFormValues }) {
  const stripePromise = useStripePromise(props.config.stripePublicKey)
  const locale = useLocale()
  return (
    <Elements
      stripe={stripePromise}
      // TODO: Enable this if we are sure it is allowed to use google fonts and add 'fonts.googleapis.com' to CONNECT_SRC_VALUES
      // options={{
      //   fonts: [
      //     {
      //       cssSrc: 'https://fonts.googleapis.com/css?family=Fira+Sans',
      //     },
      //   ],
      // }}
      options={{ locale }}
    >
      <FormWithStripeContext {...props} />
    </Elements>
  )
}

export default DonationForm
