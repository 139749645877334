import I18nHtml from '@/components/I18nHtml'
import styles from './MatchingFundBanner.module.css'
import useLocale from '@/i18n/useLocale'
import { DonationConfigWordings } from '@betterplace/api-graphql-types'
import { formatCents, toLowerCase } from '@betterplace/utils'
import { useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'

function MatchingFundBanner() {
  const locale = useLocale()
  const { matchingFundCompanyName, matchingFundLimitCents, showMatchingFund, wording } = useDonationFormConfig()
  const [amountCents] = useDonationFormValues(['amount_cents'])
  if (!showMatchingFund) return null
  // guard as we don't have locales for "informal generic" wording
  if (wording === DonationConfigWordings.InformalGeneric) {
    return null
  }

  const matchingCents = Math.min(amountCents, matchingFundLimitCents ?? 0)
  const limitModifier = amountCents > matchingCents ? '_with_limit' : ''
  const totalCents = amountCents + matchingCents
  const i18nParams = {
    total_amount_tag: formatCents(locale, totalCents),
    company_name: matchingFundCompanyName,
    donation_amount_tag: formatCents(locale, amountCents),
    matching_amount_tag: formatCents(locale, matchingCents),
    matching_limit_amount: formatCents(locale, matchingFundLimitCents ?? 0),
  }

  const i18nKey = `nextjs.donate.matching_fund_banner.${toLowerCase(wording)}_text${limitModifier}_html` as const

  return (
    <div className={styles.donationMatchingFundBanner}>
      <I18nHtml as="p" i18nKey={i18nKey} options={i18nParams} />
    </div>
  )
}

export default MatchingFundBanner
