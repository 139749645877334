import getAdditionalContext from './getAdditionalContext'
import { createHoneybadger, createReporter } from '@betterplace/error-reporting'

const honeybadger = createHoneybadger({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  environment: process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,
  tags: ['org'],
  projectRoot: 'webpack:///./',
  getAdditionalContext,
})

honeybadger.beforeNotify((notice) => {
  if (!notice) return

  notice.backtrace.forEach((line) => {
    if (line.file) {
      // TODO: we might have to adjust this depending on where the assets actually live...
      // webpack-internal:///(sc_server)/./src/...
      line.file = line.file
        .replace('webpack-internal:///', process.env.NEXT_PUBLIC_ASSET_URL)
        .replace('(sc_server)/.', '/frontend/apps/org')
    }
    return line
  })
})

const report = createReporter(honeybadger)

export default report
