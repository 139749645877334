'use client'

import styles from './OfflineMessage.module.css'
import { useNetworkStatus } from '@/helpers/hooks'
import { useTranslations } from 'next-intl'

function OfflineMessage() {
  const t = useTranslations()
  const networkStatus = useNetworkStatus()

  return networkStatus === 'offline' ? (
    <div className={styles.offlineStatusContainer}>{t('nextjs.errors.messages.offline')}</div>
  ) : null
}

export default OfflineMessage
