'use client'
import processDonationAction, { type processDonationFormAction } from '../../../../operations/processDonationAction'
import { CardElement, IbanElement } from '@stripe/react-stripe-js'
import { ConfirmCardPaymentData, ConfirmSepaDebitPaymentData } from '@stripe/stripe-js'
import { type GenericFinalizeProps, type StripeFinilizeProps } from './types'
import { NextIntlKeys, Translator } from '@/i18n/types'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { ReportableError } from '@betterplace/error-reporting'
import { getTranslatorWithFallback } from '@/i18n'
import { report } from '@/honeybadger'

import useRedirectHandler from '@/donationForm/_dependencies/helpers/useRedirectHandler'
import withThrottle from '@/donationForm/_dependencies/components/DonationForm/useDonationForm/useFinalizeHandler/withThrottle'
import { useCallback } from 'react'
import { useErrorHandler } from '@/donationForm/_dependencies/helpers'
import { useStripeContext } from '../../../../StripeContext'
import type { DonationFormValues, StripeOptions } from '@/donationForm/types'
import type { ErrorHandler, OnAfterSubmitSuccess, SetterTools } from '@/form/ActionForm/useActionForm'
import type { FieldErrors } from 'react-hook-form'
import type { OperationReturn } from '@/helpers/operations'
import type { RedirectHandler } from '@/donationForm/_dependencies/helpers/useRedirectHandler/types'

const submitHandler = async (
  {
    redirectTo,
    stripePaymentIntentClientSecret: paymentIntentClientSecret,
  }: NonNullable<OperationReturn<typeof processDonationAction>['data']>,
  values: DonationFormValues,
  tools: SetterTools<DonationFormValues>,
  stripeOptions: StripeOptions,
  redirect: RedirectHandler,
  handleError: ErrorHandler<DonationFormValues>,
  t_: Translator
) => {
  const t = getTranslatorWithFallback(t_)
  async function errorHandler(errors: FieldErrors<DonationFormValues>) {
    await handleError(errors, values, tools, { shouldSetErrors: true })
  }

  if (!redirectTo) {
    report(new TypeError('Backend did not return "redirectTo" for this request'))
    return await errorHandler({
      payment_method: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' },
    })
  }

  const throttle = withThrottle(() =>
    errorHandler({ payment_method: { message: t('nextjs.donate.errors.throttled'), type: 'validate' } })
  )
  switch (values.payment_method) {
    case PaymentMethods.StripeCc:
      return await throttle(stripeCCFinalize)({
        t,
        values,
        redirect,
        redirectTo,
        stripeOptions,
        errorHandler,
        paymentIntentClientSecret,
      })
    case PaymentMethods.StripeGiropay:
      // TODO: Remove as part of the PaymentMethods cleanup
      throw new Error('Giropay is not supported in this context')
    case PaymentMethods.StripeSepaDebit:
      return await throttle(stripeSEPADebitFinalize)({
        t,
        values,
        redirect,
        redirectTo,
        stripeOptions,
        errorHandler,
        paymentIntentClientSecret,
      })
    case PaymentMethods.Paypal:
      // PayPal's case is handled by the code inside PayPalButton, so this is just a pass-through
      return
    default:
      return otherFinalize({ redirect, redirectTo, t, errorHandler, values })
  }
}

async function stripeCCFinalize({
  stripeOptions,
  values,
  errorHandler,
  redirectTo,
  redirect,
  paymentIntentClientSecret,
  t,
}: StripeFinilizeProps) {
  if (!paymentIntentClientSecret) {
    report(new TypeError('The backend has not returned a valid stripe token for this request'))
    return await errorHandler({ stripe_token: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' } })
  }
  const card = stripeOptions?.elements?.getElement(CardElement)
  if (!card || typeof stripeOptions?.stripe?.confirmCardPayment !== 'function') {
    // show generic error message if the card element doesn't exist
    return await errorHandler({
      stripe_token: { message: t('nextjs.donate.errors.stripe_cc_unavailable'), type: 'validate' },
    })
  }

  const { first_name, last_name } = values

  const data: ConfirmCardPaymentData = {
    payment_method: {
      card,
      billing_details: {
        name: `${first_name} ${last_name}`,
      },
    },
  }

  try {
    const result = await stripeOptions.stripe.confirmCardPayment(paymentIntentClientSecret, data)
    const error = result.error
    if (error && !/already succeeded/.test(error.message ?? '')) {
      const message = t(`nextjs.donate.errors.stripe_cc_${error.code ?? 'unknown_error'}` as NextIntlKeys, {
        defaultValue: error.message,
      })
      return await errorHandler({ stripe_token: { message, type: 'validate' } })
    }
    await redirect(redirectTo, values)
  } catch (err) {
    report(err as ReportableError)
    await errorHandler({ stripe_token: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' } })
  }
}

async function stripeSEPADebitFinalize({
  stripeOptions,
  values,
  errorHandler,
  redirectTo,
  redirect,
  paymentIntentClientSecret,
  t,
}: StripeFinilizeProps) {
  if (!paymentIntentClientSecret) {
    report(new TypeError('The backend has not returned a valid stripe token for this request'))
    return await errorHandler({ stripe_token: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' } })
  }
  const sepa_debit = stripeOptions?.elements?.getElement(IbanElement)
  if (!sepa_debit || typeof stripeOptions?.stripe?.confirmSepaDebitPayment !== 'function') {
    // show generic error message if the iban element doesn't exist
    return await errorHandler({
      stripe_token: { message: t('nextjs.donate.errors.stripe_sepa_debit_unavailable'), type: 'validate' },
    })
  }
  const { first_name, last_name, _company_donation, company_name, email, city, zip } = values
  const data: ConfirmSepaDebitPaymentData = {
    payment_method: {
      sepa_debit,
      billing_details: {
        name: `${first_name} ${last_name}`,
        email,
        // make sure that a given company_name appears in the sepa mandate that is generated by Stripe
        address:
          _company_donation && company_name
            ? {
                city,
                line1: company_name,
                postal_code: zip,
              }
            : undefined,
      },
    },
  }

  try {
    const result = await stripeOptions.stripe.confirmSepaDebitPayment(paymentIntentClientSecret, data)
    const error = result.error
    if (error && !/already succeeded/.test(error.message ?? '')) {
      const message = t(`nextjs.donate.errors.stripe_sepa_debit_${error.code ?? 'unknown_error'}` as NextIntlKeys, {
        defaultValue: error.message,
      })
      return await errorHandler({ stripe_token: { message, type: 'validate' } })
    }
    await redirect(redirectTo, values)
  } catch (err) {
    report(err as ReportableError)
    await errorHandler({ stripe_token: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' } })
  }
}

async function otherFinalize({ redirect, values, redirectTo, t, errorHandler }: GenericFinalizeProps) {
  try {
    return await redirect(redirectTo, values)
  } catch (error) {
    report(error as ReportableError)
    await errorHandler({ stripe_token: { message: t('nextjs.donate.errors.unknown_error'), type: 'validate' } })
  }
}

function useFinalizeHandler(t: Translator): OnAfterSubmitSuccess<DonationFormValues, typeof processDonationFormAction> {
  const stripeOptions = useStripeContext()
  const redirect = useRedirectHandler()
  const errorHandler = useErrorHandler()
  const handler: OnAfterSubmitSuccess<DonationFormValues, typeof processDonationFormAction> = useCallback(
    (data, input, tools) => {
      return submitHandler(data, input, tools, stripeOptions, redirect, errorHandler, t)
    },
    [stripeOptions, redirect, errorHandler, t]
  )
  return handler
}

export default useFinalizeHandler
