'use client'

import DonationAmountSum from '@/donationForm/_dependencies/components/shared/DonationAmountSum'
import PaypalButton from '@/donationForm/_dependencies/components/DonationForm/formParts/SubmitButton/PaypalButton'
import analytics, { convertCentsToEuro, getDonationFormType } from '@betterplace/product-analytics'
import classNames from 'classnames'
import styles from './SubmitButton.module.css'
import { IconButton } from '@betterplace/design-system/client'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { stringify } from '@betterplace/utils'
import { useCallback, useEffect, useRef } from 'react'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

// TODO: It's only handling onClick, what about onKeyDown? Maybe moving it up to the form would be better
function useTrackSubmitForm() {
  const { showCodonation, receiverId, receiverType, channel } = useDonationFormConfig()
  const { formState, getValues } = useDonationFormContext()
  const { isValid, errors } = formState
  const formStateRef = useRef({ isValid, errors })

  useEffect(() => {
    const { isValid, errors } = formState
    formStateRef.current = { isValid, errors }
  }, [formState])

  const trackSubmitForm = useCallback(() => {
    const { isValid, errors } = formStateRef.current
    const [
      interval,
      paymentMethod,
      showAmount,
      message,
      newsletter,
      receiptDesired,
      amountCents,
      codonationCents,
      showUser,
      displayName,
    ] = getValues([
      'interval',
      'payment_method',
      'show_amount',
      'message',
      'newsletter',
      'receipt_desired',
      'amount_cents',
      'codonation_cents',
      'show_user',
      'display_name',
    ])
    if (isValid) {
      analytics.donationSubmitted({
        donation_amount: convertCentsToEuro(amountCents),
        recurring_donation: interval,
        payment_method: paymentMethod,
        codonation_amount: convertCentsToEuro(codonationCents),
        show_amount_selected: showAmount,
        show_name_selected: !!(showUser || displayName),
        donation_receipt_requested: receiptDesired,
        platform_newsletter_selected: newsletter,
        comment_added: !!message,
        id: receiverId,
        receiver_type: receiverType,
        donation_form_type: getDonationFormType({
          channel,
          receiverType,
          receiverId,
          showCodonation,
        }),
      })
    } else {
      analytics.donationErrorMessageDisplayed({
        donation_form_type: getDonationFormType({
          receiverId,
          receiverType,
          channel,
          showCodonation,
        }),
        id: receiverId,
        receiver_type: receiverType,
        error: stringify(errors),
        errors,
      })
    }
  }, [channel, getValues, receiverId, receiverType, showCodonation])

  return trackSubmitForm
}

function SubmitButton({ className: className_ }: { className?: string }) {
  const { paypalClientId } = useDonationFormConfig()
  const [paymentMethod] = useDonationFormValues(['payment_method'])
  const trackSubmitForm = useTrackSubmitForm()
  const { formState } = useDonationFormContext()
  const { isSubmitting } = formState
  const t = useTranslations()
  const className = classNames(styles.button, className_, {
    [styles.loading as string]: isSubmitting,
  })
  const buttonProps = { isBusy: isSubmitting }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSubmitting) {
      e.preventDefault()
    }
    trackSubmitForm()
  }

  switch (paymentMethod) {
    case PaymentMethods.Paypal:
      return <PaypalButton clientId={paypalClientId} onClick={trackSubmitForm} />

    case PaymentMethods.DirectDeposit:
      return (
        <IconButton
          {...buttonProps}
          className={className}
          onClick={onSubmit}
          data-testid="donate-form-donate-button"
          type="submit"
          iconName={isSubmitting ? 'spinner' : undefined}
          iconProps={{ color: 'bg-light', size: '400' }}
        >
          {t('nextjs.donate.submit_button.direct_deposit')}
        </IconButton>
      )

    default:
      return (
        <>
          <IconButton
            {...buttonProps}
            className={className}
            onClick={onSubmit}
            data-testid="donate-form-donate-button"
            type="submit"
            iconName={isSubmitting ? 'spinner' : undefined}
            iconProps={{ color: 'bg-light', size: '400' }}
          >
            <span>{t('nextjs.donate.submit_button.default.before_amount')}</span>
            <DonationAmountSum fields={['amount_cents', 'codonation_cents']} />
            <span>{t('nextjs.donate.submit_button.default.after_amount')}</span>
          </IconButton>
        </>
      )
  }
}

export default SubmitButton
