import analytics, { convertCentsToEuro, getDonationFormType } from '@betterplace/product-analytics'
import { useCallback } from 'react'
import { useDonationFormConfig } from '@/donationForm/_dependencies/helpers'
import type { DonationFormValues } from '@/donationForm/types'
import type { RedirectHandler } from './types'

// eslint-disable-next-line @typescript-eslint/no-empty-function
function delay(time: number, cb: () => void = () => {}) {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      try {
        cb()
        resolve(undefined)
      } catch (e) {
        reject(e)
      }
    }, time)
  })
}

function useTrackDonationCompleted() {
  const { receiverType, receiverId, channel, showCodonation } = useDonationFormConfig()

  const trackDonationCompleted = useCallback(
    (
      redirectTo: string,
      {
        amount_cents: amountCents,
        interval,
        payment_method: paymentMethod,
        codonation_cents: codonationCents,
        show_amount: showAmount,
        show_user: showUser,
        display_name: displayName,
        receipt_desired: receiptDesired,
        newsletter,
        message,
      }: DonationFormValues
    ) => {
      analytics.donationCompleted({
        donation_amount: convertCentsToEuro(amountCents),
        recurring_donation: interval,
        payment_method: paymentMethod,
        codonation_amount: convertCentsToEuro(codonationCents),
        show_amount_selected: showAmount,
        show_name_selected: !!(showUser || displayName),
        donation_receipt_requested: receiptDesired,
        platform_newsletter_selected: newsletter,
        comment_added: !!message,
        id: receiverId,
        receiver_type: receiverType,
        donation_form_type: getDonationFormType({
          channel,
          receiverType,
          receiverId,
          showCodonation,
        }),
        postdonation_redirect_url: redirectTo,
      })
    },
    // none of these should change during the donation process
    [channel, receiverId, receiverType, showCodonation]
  )
  return trackDonationCompleted
}

/**
 * A hook returng a RedirectHandler
 * @returns A referentially stable redirect handler that tracks the donation completed event and redirects the user to the given URL.
 */
function useRedirectHandler() {
  const trackDonationCompleted = useTrackDonationCompleted()
  const redirectHandler: RedirectHandler = useCallback(
    async (redirectTo, values) => {
      trackDonationCompleted(redirectTo, values)
      return await delay(33, () => window.location.replace(redirectTo))
    },
    [trackDonationCompleted]
  )
  return redirectHandler
}

export default useRedirectHandler
