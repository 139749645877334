'use client'

import classNames from 'classnames'
import styles from '../Debug.module.css'
import { Button, LayoutStack } from '@betterplace/design-system/client'
import { ButtonProps } from './types'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { copyToClipboard } from '@/helpers/utils'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useState } from 'react'

function FakePaymentDataButton({ paymentMethod, title, value }: ButtonProps) {
  const { paymentMethods } = useDonationFormConfig()
  const { setValue, clearErrors } = useDonationFormContext()
  const [copied, setCopied] = useState(false)

  if (!paymentMethods.includes(paymentMethod)) return null

  const fillWithFakeData: React.MouseEventHandler = (event) => {
    event.preventDefault()
    clearErrors()
    setValue('first_name', 'Test', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('last_name', 'Horst', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('receipt_desired', true, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('street', 'Hauptstr. 23', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('zip', '12345', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('city', 'Entenhausen', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setValue('email', `test_horst_${Math.floor(Math.random() * 10) + 1}@betterplace.org`, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
    setValue('payment_method', paymentMethod, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    if (value) {
      void copyToClipboard(value).then(() => setCopied(true))
    }
    // It's hard to find a good place to jump to. This is about where the iban/card-number and donate button is.
    setTimeout(() => {
      document.getElementsByTagName('h4')[3]?.scrollIntoView?.({ behavior: 'smooth' })
    }, 200)
    setTimeout(() => {
      setCopied(false)
    }, 5000) // reset and hide info
  }

  return (
    <div className={styles.fakeButtonContainer}>
      <Button onClick={fillWithFakeData} kind="secondary" size="small">
        {title}
      </Button>
      {value && (
        <span>
          {' '}
          <code>{value}</code>
        </span>
      )}
      {copied && <span className={styles.valueCopiedToast}>Copied {value}</span>}
    </div>
  )
}

const FakeCaptchaButton = ({ onClick, title }: { onClick: () => void; title: string }) => {
  return (
    <div>
      <Button onClick={onClick} kind="secondary" size="small">
        {title}
      </Button>
    </div>
  )
}

function FakeButtons() {
  const { setValue } = useDonationFormContext()
  return (
    <div className={classNames(styles.genericAdminBox, styles.forPlatformAdmins, styles.fakeButtonsList)}>
      <h2 className={styles.fakeButtonsHeading}>Fill with fake data</h2>
      <LayoutStack space="100">
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeSepaDebit}
          title="IBAN (success)"
          value="DE89370400440532013000"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeSepaDebit}
          title="IBAN (success after 3 minutes)"
          value="DE08370400440532013003"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeSepaDebit}
          title="IBAN (success + dispute)"
          value="DE35370400440532013002"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeSepaDebit}
          title="IBAN (invalid)"
          value="GB94BARC20201530093459"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (VISA US)"
          value="4242424242424242"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (initial SCA, off-session always)"
          value="4000002500003155"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (always SCA)"
          value="4000002760003184"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (initial SCA, then insufficient funds)"
          value="4000008260003178"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (Mastercard US)"
          value="5555555555554444"
        />
        <FakePaymentDataButton
          paymentMethod={PaymentMethods.StripeCc}
          title="Credit Card (VISA DE)"
          value="4000002760000016"
        />
        <FakePaymentDataButton paymentMethod={PaymentMethods.DirectDeposit} title="Direct Deposit" />
        <FakePaymentDataButton paymentMethod={PaymentMethods.Paypal} title="PayPal" />
        <FakePaymentDataButton paymentMethod={PaymentMethods.DummyPayment} title="Dummy Payment (only for testing!)" />
        <FakeCaptchaButton
          title="Fake empty CAPTCHA"
          onClick={() => {
            setValue('captcha_solution', '', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
          }}
        ></FakeCaptchaButton>
        <FakeCaptchaButton
          title="Fake invalid CAPTCHA"
          onClick={() => {
            setValue('captcha_solution', 'invalid', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
          }}
        ></FakeCaptchaButton>
      </LayoutStack>
    </div>
  )
}

export default FakeButtons
