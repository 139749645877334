import { ToggleButton, type ToggleButtonProps } from '@betterplace/design-system/client'
import { forwardRef } from 'react'

const FormToggleButton = forwardRef<HTMLInputElement, ToggleButtonProps>(function FormToggleButton(
  { children, className, ...props }: ToggleButtonProps,
  ref
) {
  const { disabled, name } = props

  return (
    <ToggleButton {...props} id={name} ref={ref} className={className} isDisabled={disabled} disabled={undefined}>
      {children}
    </ToggleButton>
  )
})

export default FormToggleButton
