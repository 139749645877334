'use client'

import DirectDepositInfo from './DirectDepositInfo'
import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import FiscalYearChangeNotice from './FiscalYearChangeNotice'
import { DonationIntervals, PaymentMethods } from '@betterplace/api-graphql-types'
import { Fieldset, FieldsetProps, Heading, RadioButton, Tooltip } from '@betterplace/design-system/client'
import { toLowerCase } from '@betterplace/utils'
import { useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

import styles from './PaymentMethodSelection.module.css'

function PaymentMethodSelection({
  className,
  layout = 'vertical',
}: {
  className?: string
  layout?: FieldsetProps['layout']
}) {
  const t = useTranslations()
  const { paymentMethods, recurringPaymentMethods } = useDonationFormConfig()
  const [interval, paymentMethod] = useDonationFormValues(['interval', 'payment_method'])

  const isRecurring = interval !== DonationIntervals.Single
  const [isTwitterBrowser, setIsTwitterBrowser] = useState(false)
  useEffect(() => {
    typeof navigator !== 'undefined' && setIsTwitterBrowser(/Twitter/i.test(navigator.userAgent))
  }, [])
  return (
    <>
      <Fieldset
        legend={
          <Heading level="h200" className={styles.headingMargin}>
            {t('nextjs.donate.payment_method.headline')}
          </Heading>
        }
        layout={layout}
        layoutProps={{ className }}
        className={styles.fieldset}
      >
        {paymentMethods.map((name) => {
          // TODO: Remove this when cleaning up after Giropay and removing unused payment methods
          if (name === PaymentMethods.StripeGiropay) throw new Error('Giropay is not supported in this context')
          const isBrowserUnsupported = name === PaymentMethods.Paypal && isTwitterBrowser
          const isRecurringUnsupported = isRecurring && !recurringPaymentMethods.includes(name)

          const isDisabled = isBrowserUnsupported || isRecurringUnsupported
          return (
            <div key={name}>
              <Tooltip
                content={
                  isBrowserUnsupported
                    ? t('nextjs.donate.errors.paypal_unsupported_browser')
                    : isRecurringUnsupported
                      ? t('nextjs.donate.recurring_disabled_tooltip')
                      : null
                }
                inactive={!isDisabled}
              >
                <DonationFormField
                  name="payment_method"
                  type="radio"
                  as={RadioButton}
                  value={name}
                  disabled={isDisabled}
                  classNames={{ container: styles.radio }}
                >
                  {t(`nextjs.donate.payment_method.labels.${toLowerCase(name)}`)}
                </DonationFormField>
              </Tooltip>
            </div>
          )
        })}
      </Fieldset>
      <DirectDepositInfo paymentMethod={paymentMethod} />
      <FiscalYearChangeNotice paymentMethod={paymentMethod} />
    </>
  )
}

export default PaymentMethodSelection
