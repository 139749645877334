'use client'
import Image from 'next/image'
import PayPalLogo from '@betterplace/assets/images/logos/paypal.svg'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './PaypalButton.module.css'
import { ActionFormSubmitErrorTypes } from '@/form/ActionForm/useActionForm/useActionForm'
import {
  DISPATCH_ACTION,
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalScriptProvider,
  ReactPayPalScriptOptions,
  SCRIPT_LOADING_STATE,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import { Icon } from '@betterplace/design-system/client'
import { ReportableError } from '@betterplace/error-reporting'
import { report } from '@/honeybadger'
import { useDonationFormConfig, useDonationFormContext, useRedirectHandler } from '@/donationForm/_dependencies/helpers'
import { useLocale, useTranslations } from 'next-intl'
import { useMessageContext } from '@/components/Messages'
import { useNetworkStatus } from '@/helpers/hooks'
import type { Maybe } from '@/types'

function useFormSubmit(): {
  redirectToRef: React.MutableRefObject<string | undefined>
  createBillingAgreement: () => Promise<string>
} {
  const t = useTranslations()
  const redirectToRef = useRef<string | undefined>(undefined)
  const { addMessage } = useMessageContext()

  const { submit, setBusy } = useDonationFormContext()

  const createBillingAgreement = useCallback(async () => {
    redirectToRef.current = undefined
    setBusy(true)
    try {
      const {
        data: { redirectTo, paypalToken },
      } = await submit()
      if (!paypalToken) throw new Error('No paypal token received')
      if (!redirectTo) throw new Error('No redirect url received')
      redirectToRef.current = redirectTo
      return paypalToken
    } catch (e_) {
      const e = e_ as Maybe<Error>
      if (e?.message === ActionFormSubmitErrorTypes.ValidationError) {
        throw e
      }
      addMessage({ message: t('nextjs.errors.messages.submit'), type: 'error' })
      report(e as ReportableError)
      setBusy(false)
      return ''
    }
  }, [addMessage, setBusy, submit, t])

  return { createBillingAgreement, redirectToRef }
}

function useFieldErrorsKeys() {
  const { formState } = useDonationFormContext()
  const { errors } = formState
  const keys = useMemo(() => Object.keys(errors ?? {}).filter((key) => key !== 'payment_method'), [errors])
  return keys
}

/**
 * Function skips the "general"  payment_method error, so that we only evaluate the validity of the field itself
 * @returns boolean
 */
function useIsFormValid() {
  const { formState } = useDonationFormContext()
  const { isValid } = formState
  const keys = useFieldErrorsKeys()
  return isValid || !keys.length
}

function usePaypalOptions(clientId?: string) {
  const locale = useLocale()
  const [options, setOptions] = useState<ReactPayPalScriptOptions>({
    clientId: '',
    commit: true,
    currency: 'EUR',
    intent: 'tokenize',
    disableFunding: 'card,sepa',
    vault: true,
    locale: locale === 'de' ? 'de_DE' : 'en_US',
    dataReactPaypalScriptId: 'sdk-paypal-script',
    dataNamespace: 'sdk-paypal', // solves the issue with paypal namespace conflicting with input#paypal
    components: 'buttons',
  })
  useEffect(() => {
    setOptions((old) => ({ ...old, clientId: clientId ?? '' }))
  }, [clientId])
  return options.clientId ? options : undefined
}

function usePayPalButtonProps(): PayPalButtonsComponentProps & { error?: PayPalFatalError } {
  const isValid = useIsFormValid()
  const { debug } = useDonationFormConfig()
  const { setBusy, formState, getValues } = useDonationFormContext()
  const { isSubmitting } = formState
  const redirectHandler = useRedirectHandler()
  const { createBillingAgreement, redirectToRef } = useFormSubmit()
  const onApprove = useCallback(async () => {
    if (redirectToRef.current) {
      await redirectHandler(redirectToRef.current, getValues())
    }
  }, [redirectHandler, redirectToRef, getValues])

  const onCancel = useCallback(() => {
    setBusy(false)
  }, [setBusy])

  const [error, setError] = useState<PayPalFatalError | undefined>(undefined)

  const onError = useCallback(
    (e_: unknown) => {
      setBusy(false)
      const e = e_ as Maybe<Error>
      if (e?.message === ActionFormSubmitErrorTypes.ValidationError) {
        return
      }
      setError(PayPalFatalError.Other)
    },
    [setBusy, setError]
  )

  const env = useMemo(() => (debug ? 'sandbox' : 'production'), [debug])

  const props = useMemo(
    () => ({
      onCancel,
      onError,
      isSubmitting,
      createBillingAgreement,
      onApprove,
      error,
      env,
      style: buttonStyle,
      disabled: !isValid,
    }),
    [onCancel, onError, isSubmitting, createBillingAgreement, onApprove, error, env, isValid]
  )
  return props
}

const PaypalButton = memo(function PaypalButton({ clientId, onClick }: { clientId?: string; onClick?: () => void }) {
  const options = usePaypalOptions(clientId)
  if (!options) return <NoPaypalJsMessage />
  return (
    <PayPalScriptProvider deferLoading options={options}>
      <PayPalButtonInner onClick={onClick} />
    </PayPalScriptProvider>
  )
})

enum PayPalButtonStatus {
  Initial = 'Initial',
  ReadyToLoad = 'ReadyToLoad',
  FieldError = 'FieldError',
  FatalError = 'FatalError',
  Ok = 'Ok',
  Busy = 'Busy',
  Offline = 'Offline',
}

enum PayPalFatalError {
  LoadingFailed = 'LoadingFailed',
  TooManyRequests = 'TooManyRequests',
  UnsupportedBrowser = 'UnsupportedBrowser',
  Other = 'Other',
}

function usePayPalStatus(props: Pick<ReturnType<typeof usePayPalButtonProps>, 'error'> | undefined) {
  const [{ isResolved, isRejected, isInitial, isPending }] = usePayPalScriptReducer()
  const { formState } = useDonationFormContext()
  const { isDirty, isSubmitting } = formState
  const networkStatus = useNetworkStatus()
  const isValid = useIsFormValid()
  const isOffline = networkStatus === 'offline'
  const isUnsupportedBrowser = /Twitter/i.test(navigator.userAgent)
  const result = useMemo(() => {
    if (!props) return [PayPalButtonStatus.Initial, undefined] as const
    if (props.error) return [PayPalButtonStatus.FatalError, props.error] as const
    if (isUnsupportedBrowser) return [PayPalButtonStatus.FatalError, PayPalFatalError.UnsupportedBrowser] as const
    if (isInitial && isValid && isDirty) return [PayPalButtonStatus.ReadyToLoad, undefined] as const
    if (isInitial) return [PayPalButtonStatus.Initial, undefined] as const
    if (isPending || isSubmitting) return [PayPalButtonStatus.Busy, undefined] as const
    if (!isValid) return [PayPalButtonStatus.FieldError, undefined] as const
    if (isOffline) return [PayPalButtonStatus.Offline, undefined] as const
    if (isRejected) return [PayPalButtonStatus.FatalError, PayPalFatalError.LoadingFailed] as const
    if (isResolved) return [PayPalButtonStatus.Ok, undefined] as const
    return [PayPalButtonStatus.Busy, undefined] as const
  }, [
    isDirty,
    isInitial,
    isOffline,
    isPending,
    isRejected,
    isResolved,
    isSubmitting,
    isUnsupportedBrowser,
    isValid,
    props,
  ])
  return result
}

function useLoadPayPalWhenReady(status: PayPalButtonStatus) {
  const [_, dispatch] = usePayPalScriptReducer()
  useEffect(() => {
    if (status !== PayPalButtonStatus.ReadyToLoad) return
    dispatch({ type: DISPATCH_ACTION.LOADING_STATUS, value: SCRIPT_LOADING_STATE.PENDING })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])
}

function PayPalButtonInner({ onClick }: { onClick?: () => void }) {
  const { error, ...props } = usePayPalButtonProps()
  const [status, fatalError] = usePayPalStatus({ error })
  useLoadPayPalWhenReady(status)
  if (status === PayPalButtonStatus.FatalError) return <FatalErrorMessage error={fatalError} />
  const busy = status === PayPalButtonStatus.Busy
  const ok = status === PayPalButtonStatus.Ok
  return (
    <div className={styles.container} style={{ height: buttonHeight }}>
      <DummyPayPalButton busy={busy} onClick={onClick} />
      <div style={!ok ? { display: 'none' } : {}}>
        <PayPalButtons onClick={onClick} {...props} />
      </div>
    </div>
  )
}

function DummyPayPalButton({ busy, onClick: onClick_ }: { busy?: boolean; onClick?: () => void }) {
  const t = useTranslations()
  const errorFields = useFieldErrorsKeys()
  const onClick = useCallback(() => {
    if (busy || !errorFields.length) return
    onClick_?.()
  }, [onClick_, busy, errorFields])

  return (
    <div style={dummyButtonStyle}>
      <button
        style={{ height: buttonHeight, backgroundColor: 'transparent' }}
        className={styles.dummyPaypalButton}
        onClick={onClick}
        disabled={busy}
        type="submit"
      >
        {busy && <Icon color="bg-light" name="spinner" size="400" />}
        <Image style={{ display: 'inline-block' }} src={PayPalLogo as string} alt={t('nextjs.core.submit')} />
      </button>
    </div>
  )
}

function FatalErrorMessage({ error }: { error?: PayPalFatalError }) {
  if (typeof error === 'undefined') return null
  switch (error) {
    case PayPalFatalError.TooManyRequests:
      return <Error429Message />
    case PayPalFatalError.LoadingFailed:
      return <NoPaypalJsMessage />
    case PayPalFatalError.UnsupportedBrowser:
      return <UnsupportedBrowserMessage />
    default:
      return <OtherErrorMessage />
  }
}

function NoPaypalJsMessage() {
  const t = useTranslations()
  return <p className={styles.errorMessage}>{t('nextjs.donate.errors.paypal_ad_blocker')}</p>
}

function Error429Message() {
  const t = useTranslations()
  return (
    <>
      <p className={styles.errorMessage}>{t('nextjs.donate.errors.paypal_too_many_requests')}</p>
      <p className={styles.errorMessage}>{t('nextjs.donate.errors.paypal_too_many_requests_settings')}</p>
    </>
  )
}

function OtherErrorMessage() {
  const t = useTranslations()
  return <p className={styles.errorMessage}>{t('nextjs.donate.errors.paypal_other')}</p>
}

function UnsupportedBrowserMessage() {
  const t = useTranslations()
  return <p className={styles.errorMessage}>{t('nextjs.donate.errors.paypal_unsupported_browser')}</p>
}

const buttonHeight = 50

const buttonStyle: PayPalButtonsComponentProps['style'] = {
  height: buttonHeight,
  color: 'gold',
  fundingicons: false, // does not exist on props thus casting below
  label: 'checkout',
  shape: 'rect',
  size: 'responsive',
  tagline: false,
} as PayPalButtonsComponentProps['style']

const dummyButtonStyle: React.CSSProperties = {
  height: buttonHeight,
  margin: 0,
  textAlign: 'center',
  zIndex: -1,
  position: 'absolute',
  left: 0,
  right: 0,
}
export default PaypalButton
