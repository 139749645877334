import Image, { StaticImageData } from 'next/image'
import React from 'react'
import TrustedHtml from '@/components/TrustedHtml'
import classNames from 'classnames'
import styles from './Modal.module.css'
import wirWunderLogo from '@betterplace/assets/images/logos/wirwunder_logo.svg'
import { Button } from '@betterplace/design-system/client'
import { MatchingEventModalInfoFieldsFragment, Maybe } from '@betterplace/api-graphql-types'
import { useTranslations } from 'next-intl'

function ModalContent({
  backgroundColor,
  logo,
  branchLogo,
  modalInfo: { duration, budget, maximumAmount, furtherInfo, extraInfo },
  onHide,
  donateButton,
}: {
  backgroundColor: string
  branchLogo?: Maybe<string>
  logo?: Maybe<string>
  modalInfo: MatchingEventModalInfoFieldsFragment
  onHide: React.MouseEventHandler<HTMLButtonElement>
  donateButton?: React.ReactNode
}) {
  const t = useTranslations()

  return (
    <div className={styles.container}>
      <div data-testid="matching-event-modal-content" className={styles.logoContainer} style={{ backgroundColor }}>
        <Image className={styles.logo} src={logo ?? (wirWunderLogo as StaticImageData)} alt="" width={45} height={45} />
        {!logo && branchLogo && <Image className={styles.logo} src={branchLogo} alt="" width={45} height={45} />}
      </div>
      <div>
        <TrustedHtml as="p" value={duration} />
        <TrustedHtml as="p" value={budget} className={styles.infoMiniParagraph} />
        <TrustedHtml as="p" value={maximumAmount} className={styles.infoMiniParagraph} />
        <TrustedHtml as="p" value={furtherInfo} className={styles.infoParagraph} />
        {extraInfo && <TrustedHtml as="p" value={extraInfo} className={styles.infoParagraph} />}
      </div>
      <div
        className={classNames(
          styles.buttonWrapper,
          donateButton ? styles.multipleButtonsWrapper : styles.singleButtonWrapper
        )}
      >
        <Button
          type="button"
          onClick={onHide}
          kind={donateButton ? 'plain' : 'primary'}
          size={donateButton ? 'small' : 'default'}
        >
          {t('nextjs.core.close')}
        </Button>
        {donateButton && donateButton}
      </div>
    </div>
  )
}

export default ModalContent
