import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import FormInput from '../../../shared/FormInput'
import styles from './YoutubeFields.module.css'
import { ChangeEventHandler, FocusEventHandler, useCallback } from 'react'
import { Fieldset, Heading, LayoutStack } from '@betterplace/design-system/client'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function createVideoStartTime(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}

function tryToFixTimestamp(timestamp: string) {
  let fixedTimestamp = timestamp
  if (fixedTimestamp.match(/^[0-9]+(:[0-9]+){1,2}$/)) {
    const timestampArray = fixedTimestamp.split(':')
    fixedTimestamp = timestampArray.map((e) => (e.match(/^[0-9]{1}$/) ? '0' + e : e)).join(':')
  }
  if (fixedTimestamp.match(/^[0-9]{2}:[0-9]{2}$/)) {
    return '00:' + fixedTimestamp
  }
  return fixedTimestamp
}

function YoutubeFields() {
  const t = useTranslations('nextjs.donate')
  const { showStreamingShareableMedia } = useDonationFormConfig()
  const { setValue } = useDonationFormContext()
  const setVideoStartTime = useCallback(
    (value: string) => {
      setValue('video_start_time', value, { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  const onVideoLinkChanged: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const url = target.value
      if (!url) {
        setVideoStartTime('00:00:00')
        return
      }
      try {
        // eslint-disable-next-line no-var
        var parsed = new URL(url)
      } catch {
        return
      }
      const params = parsed.searchParams
      const tParam = params.get('t')
      const totalSeconds = Number(tParam)
      if (!tParam || Number.isNaN(totalSeconds)) return
      params.delete('t')
      target.value = parsed.toString()
      const videoStartTime = createVideoStartTime(totalSeconds)
      setVideoStartTime(videoStartTime)
    },
    [setVideoStartTime]
  )
  const onVideoStartTimeInputLeave: FocusEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const timestamp = target.value
      if (timestamp === '') {
        setVideoStartTime('00:00:00')
        return
      }
      if (!timestamp.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
        const fixedTimestamp = tryToFixTimestamp(timestamp)
        if (fixedTimestamp !== timestamp) setVideoStartTime(fixedTimestamp)
      }
    },
    [setVideoStartTime]
  )
  if (!showStreamingShareableMedia) return null
  return (
    <LayoutStack>
      <Fieldset
        className={styles.youtubeFieldset}
        layoutProps={{
          space: '400',
        }}
        legend={
          <Heading level="h200" className={styles.youtubeHeading}>
            {t('youtube_fields.headline')}
          </Heading>
        }
        layout="vertical"
      >
        <DonationFormField
          description={t('youtube_fields.video_link_hint')}
          name="video_link"
          type="url"
          as={FormInput}
          label={t('attributes.video_link')}
          onChange={onVideoLinkChanged}
        />
        <DonationFormField
          name="video_start_time"
          as={FormInput}
          label={t('attributes.video_start_time')}
          defaultValue="00:00:00"
          placeholder="hh:mm:ss"
          onBlur={onVideoStartTimeInputLeave}
        />
      </Fieldset>
    </LayoutStack>
  )
}

export default YoutubeFields
