import FakeButtons from './FakeButtons'
import classNames from 'classnames'
import styles from './Debug.module.css'
import { JSONPrettyPrint } from '@betterplace/design-system/client'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useEffect, useState } from 'react'

function FormDebug() {
  const { watch, getValues, formState } = useDonationFormContext()
  const data = { values: { ...watch(), ...getValues() }, ...formState }
  return (
    <details className={classNames(styles.genericAdminBox, styles.forPlatformAdmins, styles.formDebug)}>
      <summary>Form Debug</summary>
      <JSONPrettyPrint json={data} />
    </details>
  )
}

function JSErrors() {
  const [errors, setErrors] = useState<ErrorEvent[] | []>([])

  useEffect(() => {
    const addError = (e: ErrorEvent) => setErrors([...errors, e])
    window.addEventListener('error', addError)
    return () => window.removeEventListener('error', addError)
  }, [setErrors, errors])

  return (
    <details className={classNames(styles.genericAdminBox, styles.forPlatformAdmins, styles.jsErrors)}>
      <summary>JS Errors</summary>
      <table className={classNames(styles.debugTable, styles.debugTableStriped)}>
        <tbody>
          {errors.map((e, i) => (
            <tr key={i}>
              <td>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {e.error.name || <span>Error</span>}
                <span>:&nbsp;</span>
                {e.message}
              </td>
              <td>
                {e.filename}:{e.lineno}:{e.colno}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </details>
  )
}

function Debug() {
  const { debug } = useDonationFormConfig()
  if (!debug) return null

  return (
    <>
      <FakeButtons />
      <FormDebug />
      <JSErrors />
    </>
  )
}

export default Debug
